import produce from 'immer';
import { CLEAR_STORE, SET_EMPRESA_LOGADA, SET_USUARIO_LOGADO, START_LOADING, STOP_LOADING } from './types';

interface State {
  loading: boolean;
  usuarioLogado: object;
  empresaLogada: object;
}

interface Action {
  type: string;
  payload?: any;
}

const initialState: State = {
  loading: false,
  usuarioLogado: {},
  empresaLogada: {}
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case CLEAR_STORE:
      return clearStore();
    case START_LOADING:
      return startLoading();
    case STOP_LOADING:
      return stopLoading();
    case SET_USUARIO_LOGADO:
      return setUsuarioLogado(action);
    case SET_EMPRESA_LOGADA:
      return setEmpresaLogada(action); 
    default:
      return state;
  }

  function clearStore(): State {
    return produce(state, (draft) => {
      draft.loading = false;
      draft.usuarioLogado = {};
      draft.empresaLogada = {};
    });
  }

  function startLoading(): State {
    return produce(state, (draft) => {
      draft.loading = true;
    });
  }

  function stopLoading(): State {
    return produce(state, (draft) => {
      draft.loading = false;
    });
  }

  function setUsuarioLogado(action: Action): State {
    return produce(state, (draft) => {
      draft.usuarioLogado = action.payload;
    });
  }

  function setEmpresaLogada(action: Action): State {
    return produce(state, (draft) => {
      draft.empresaLogada = action.payload;
    });
  }
}
