import React, { Component } from 'react'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { getStore } from '../redux/store'

export class AppProfile extends Component {
  constructor (props:any) {
    super(props)
    this.state = {
      empresa: null,
      visibleTrocarEmpresaModal: false,
      selectedCompany: {},
      empresaLogada: {}
    }
  }

  getEmpresasAcesso = async () => {
    // try {
    //   const empresasAcessoUsuario = await UsuarioService.empresasAcessoUsuarioLogado()
    //   const companies = empresasAcessoUsuario.filter(company => company.codigo_emp !== getStore().empresaLogada.codigo_emp)
    //   this.setState({ companies })
    // } catch (e) {
    //   console.error(e)
    // }
  }

//   componentDidMount () {
//     this.getEmpresasAcesso()
//   }

  async handleTrocarEmpresa () {
    // try {
    //   if (this.state.selectedCompany.codigo_emp) {        
    //     const credentials = await EmpresaService.trocarEmpresa(this.state.selectedCompany.codigo_emp)
    //     setCredentials(credentials)
    //     this.setState({ visibleTrocarEmpresaModal: false })
    //     dispatch(setEmpresaLogada({}))
    //     this.props.history.push('/home')
    //     window.location.reload()
    //   }
    // } catch (error) {
    //   showErrorMessage(error.message || 'Erro ao tentar trocar de empresa!')
    // }
  }

  render () {
    // const empresaLogada = getStore().empresaLogada

    return (
      <>
        <div className="layout-profile">
          <div className="layout-profile-link">
            <div className="p-grid">
              <div className="p-col-12 company mb-3">{"Empresa de Energia LTDA"}</div>                
            </div>
          </div>                
        </div>            
      </>        
    )
  }
}
