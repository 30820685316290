import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { ToastMessage } from 'primereact/toast';

let showMessageFn: (toastMessage: ToastMessage | ToastMessage[]) => void;

class Message extends Component {
  private toast: Toast | null = null;

  render() {
    return <Toast ref={(el) => (this.toast = el)} />;
  }

  componentDidMount() {
    showMessageFn = this.showMessage;
  }

  addToast = (toastMessage: ToastMessage | ToastMessage[]) => {
    const time = 100;

    if (Array.isArray(toastMessage)) {
      toastMessage.forEach((message) => {
        if (!message.life) {
          // Verificação mais robusta para garantir que 'detail' seja uma string
          const newLife = typeof message.detail === 'string' ? message.detail.length * time : 0;
          if (newLife) {
            message.life = newLife;
          }
        }
      });
    } else {
      if (!toastMessage.life) {
        const newLife = typeof toastMessage.detail === 'string' ? toastMessage.detail.length * time : 0;
        if (newLife) {
          toastMessage.life = newLife;
        }
      }
    }

    this.toast?.show(toastMessage);
  };

  showMessage = (toastMessage: ToastMessage | ToastMessage[]) => {
    this.addToast(toastMessage);
  };
}

/**
 * Exibe uma ou mais mensagens na página
 * @param toastMessage Mensagem ou lista de mensagens para exibir
 */
export function showMessage(toastMessage: ToastMessage | ToastMessage[]) {
  if (showMessageFn) {
    showMessageFn(toastMessage);
  }
}

export const showErrorMessage = (message: string) => {
  showMessage({ severity: 'error', summary: 'Erro', detail: message });
};

export const showSuccessMessage = (message: string) => {
  showMessage({ severity: 'success', summary: 'Sucesso', detail: message });
};

export const showWarnMessage = (message: string) => {
  showMessage({ severity: 'warn', summary: 'Atenção', detail: message });
};

export default Message;
