import ServiceBase from "../ServiceBase"

export default class LoginService {
    async login (data:any) {
        console.log('data: ',data)
      return await ServiceBase.postBase('auth/login', data)
    }    
    static async getUsuarioByEmailSenha (data:any) {
      return await ServiceBase.postBase('usuarioByEmailSenha', data)
    }
  }
  