import axios from 'axios'
import { authToken, logout } from './auth'
import { dispatch } from '../components/redux/store'
import { startLoading, stopLoading } from '../components/redux/actions'
import { prod, dev, loc } from '../config'

let config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

console.log('process.env.REACT_APP_STAGE: ',process.env.REACT_APP_STAGE)
switch (process.env.REACT_APP_STAGE) {
case 'loc':
  config = loc
  break
case 'prod':
  config = prod
  break
default:
  config = dev
}

export const API_URL = config.apiGateway.URL

export function server (loading = true) {
  let connection = axios.create({
    baseURL: API_URL,
    responseType: 'json',
    headers: {
      Authorization: 'Bearer ' + authToken(),     
      LoadingState: loading,
    }
  })

  connection.interceptors.request.use(function (config) {    
    if (config.headers['LoadingState']) {
      dispatch(startLoading())
    }    
    return config
  }, function (error) {
    //  if (config.headers['LoadingState']) {
    //    dispatch(startLoading())
    // }    
    return Promise.reject(error)
  })

  connection.interceptors.response.use(function (response) {
    
    dispatch(stopLoading())    
    return response
  }, function (error) {
    if (error.response && error.response.status === 401) {
      logout()
      //location.reload()
    } else {
    // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
    // Do something with response error
      dispatch(stopLoading())      
      return Promise.reject(error)
    }
  })

  return connection
}
