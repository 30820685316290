import React, { Component } from 'react'
import { connect } from 'react-redux'
import Message from '../utils/Message'
import { AppTopbar } from './AppTopBar'
import { AppProfile } from './AppProfile'
import { AppMenu } from './AppMenu'

class AppContent extends Component {
    private menu:any;    
  
    createMenu () {    

    this.menu = [{
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home'
    }]
    // const itemsCadastros = []
 
    //   itemsCadastros.push({
    //     label: 'Empresa',
    //     icon: 'pi pi-fw',
    //     reactIcon: VscCircleFilled,
    //     to: '/empresas'
    //   },
    //   {
    //     label: 'Usuário',
    //     icon: 'pi pi-fw',
    //     reactIcon: VscCircleFilled,
    //     to: '/usuarios'
    //   })
    
    
    // this.menu.push({
    //   label: 'Cadastros',
    //   icon: 'pi pi-fw',
    //   reactIcon: BsInboxes,
    //   items: itemsCadastros
    // })   
  }

  addClass (element:any, className:any) {
    if (element.classList)
      element.classList.add(className)
    else
      element.className += ' ' + className
  }

  removeClass (element:any, className:any) {
    if (element.classList)
      element.classList.remove(className)
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }
  isDesktop () {
    return window.innerWidth > 1024
  }
    render () {    
    return (
      <>       
          <div>
            <AppTopbar/>

            <div className="layout-sidebar layout-sidebar-light">
              <div className="layout-logo">
                <object data="/assets/layout/images/logo.svg"></object>
              </div>
              <AppProfile {...this.props} />
              <AppMenu/>
            </div>
            <div className="layout-main">              
            </div>
            <div className="layout-mask"></div>           
            <Message />
          </div>        
      </>
    )
  }
}

const mapStateToProps = (state:any) => ({ loading: state.loading})

export default connect(mapStateToProps)(AppContent)
