import { SET_EMPRESA_LOGADA, SET_USUARIO_LOGADO, START_LOADING, STOP_LOADING } from './types'

export const setUsuarioLogado = (payload:any) => {
  return { type: SET_USUARIO_LOGADO, payload }
}

export const setEmpresaLogada = (payload:any) => {
  return { type: SET_EMPRESA_LOGADA, payload }
}

export const startLoading = () => {
  return { type: START_LOADING }
}

export const stopLoading = () => {
  return { type: STOP_LOADING }
}