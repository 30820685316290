import moment from 'moment-timezone';
import { showSuccessMessage } from '../components/utils/Message';

export default class CommonHelper {
  static isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  static isBiggerResolution(): boolean {
    return window.innerWidth > 1500;
  }

  static currencyToInteger(value: any | number | undefined): number {
    if (!value) return 0;
    return parseInt(String(value * 100).split('.')[0]);
  }

  static integerToCurrency(value: string | number | undefined): number | null {
    if (!value) return null;
    return Number(value) / 100;
  }

  static dateToAmerican(date: string | undefined): string | null {
    if (!date) return null;
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  static dateTimeToAmerican(date: string | undefined): string | null {
    if (!date) return null;
    return moment(date, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
  }

  static dateToBrazilian(date: string | undefined): string | null {
    if (!date) return null;
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  static todayToAmerican(): string {
    return moment().format('YYYY-MM-DD');
  }

  static todayToBrazilian(): string {
    return moment().format('DD/MM/YYYY');
  }

  static currentTime(): string {
    return moment().format('HH:mm:ss');
  }

  static nextMonthToAmerican(): string {
    return moment().add(1, 'M').format('YYYY-MM-DD');
  }

  static nextMonthToBrazilian(): string {
    return moment().add(1, 'M').format('DD/MM/YYYY');
  }

  static onlyNumbers(value: string | number | undefined): string {
    if (value) {
      const anyNonDigitRegExp = /[^0-9]/g;
      return value.toString().replace(anyNonDigitRegExp, '');
    }
    return '';
  }

  static clearDigitsSpecialChars(value: string | undefined): string {
    if (value) {
      const anyNonDigitRegExp = /[^a-zA-Z0-9]+/g;
      return value.toString().replace(anyNonDigitRegExp, '');
    }
    return '';
  }

  static removeBlankSpaces(value: string | undefined): string {
    if (value) {
      return value.replace(/\s\s+/g, ' ').trimEnd();
    }
    return '';
  }

  static formatToPlaca(value: string | undefined): string {
    if (value) {
      return value.replace(/(\w{3})?(\w{4})/, '$1-$2');
    }
    return '';
  }

  static formatDocument(value: string | undefined): string {
    if (!value) return '';
    if (value.length === 14) {
      return this.formatCnpj(value);
    }
    return this.formatCpf(value);
  }

  static formatCpf(value: string): string {
    let rawValue = this.onlyNumbers(value);
    let newValue = rawValue.substr(0, 3);
    if (rawValue.length > 3) {
      newValue += '.' + rawValue.substr(3, 3);
    }
    if (rawValue.length > 6) {
      newValue += '.' + rawValue.substr(6, 3);
    }
    if (rawValue.length > 9) {
      newValue += '-' + rawValue.substr(9, 2);
    }
    return newValue;
  }

  static formatCnpj(value: string): string {
    let rawValue = this.onlyNumbers(value);
    let newValue = rawValue.substr(0, 2);
    if (rawValue.length > 2) {
      newValue += '.' + rawValue.substr(2, 3);
    }
    if (rawValue.length > 5) {
      newValue += '.' + rawValue.substr(5, 3);
    }
    if (rawValue.length > 8) {
      newValue += '/' + rawValue.substr(8, 4);
    }
    if (rawValue.length > 12) {
      newValue += '-' + rawValue.substr(12, 2);
    }
    return newValue;
  }

  static validateDocument(value: string): string {
    value = this.onlyNumbers(value);
    if (!value) return '';
    if (value.length === 14) {
      return this.validateCnpj(value) ? 'Valid CNPJ' : 'Invalid CNPJ';
    }
    return this.validateCpf(value) ? 'Valid CPF' : 'Invalid CPF';
  }

  static validateCnpj(strCNPJ: string): boolean {
    if (strCNPJ === '') return false;
    if (strCNPJ.length !== 14 || /^(\d)\1{13}$/.test(strCNPJ)) return false;
    let tamanho = strCNPJ.length - 2;
    let numeros:any = strCNPJ.substring(0, tamanho);
    let digitos = strCNPJ.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    return resultado === parseInt(digitos.charAt(1));
  }

  static validateCpf(strCPF: string): boolean {
    if (strCPF === '') return false;
    if (strCPF.length !== 11 || /^(\d)\1{10}$/.test(strCPF)) return false;
    let add = 0;
    let rev = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(strCPF.charAt(i)) * (10 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(strCPF.charAt(9))) return false;

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(strCPF.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    return rev === parseInt(strCPF.charAt(10));
  }

  static ordenarPorChave(lista: any[], chave: string): void {
    lista.sort((a, b) => {
      let nomeA = a[chave].toUpperCase(); // Ignora maiusculas e min
      let nomeB = b[chave].toUpperCase();
      return nomeA < nomeB ? -1 : nomeA > nomeB ? 1 : 0;
    });
  }

  static downloadFile(dataType: string, data: string, filename: string): void {
    let element = document.createElement('a');
    element.setAttribute('href', dataType + data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  static arrayEquals(primeiroArray: any[], segundoArray: any[]): boolean {
    return Array.isArray(primeiroArray) &&
      Array.isArray(segundoArray) &&
      primeiroArray.length === segundoArray.length &&
      primeiroArray.every((propriedade, index) => propriedade === segundoArray[index]);
  }

  static calcularDiferencaEmDias(data: Date): number {
    const dataAtual = new Date();
    const dataSemHoraAtual = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate());
    const dataSemHoraFornecida = new Date(data.getFullYear(), data.getMonth(), data.getDate());
    const diferencaEmMilissegundos = dataSemHoraAtual.getTime() - dataSemHoraFornecida.getTime();
    return Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
  }

  static copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      showSuccessMessage('Conteúdo copiado para a área de transferência!');
    }).catch(err => {
      console.error('Erro ao copiar o texto: ', err);
    });
  }
}
