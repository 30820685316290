import { Component } from "react"

class AppSubmenu extends Component {
    render () {
        return (
            <></>
        )}
}

export class AppMenu extends Component {
    render () {
        return (
            <>Aqui é o menu</>
        )}
}