export const loc = {
    apiGateway: {
      URL: 'http://localhost:5000/api/v1/'
    },
    clientInfo: {
      APP_URL: 'http://localhost:3000/'
    }
  }
  
  export const dev = {
    apiGateway: {
      URL: 'https://apidev.smartpdi.com.br/api/v1/'
    },
    clientInfo: {
      APP_URL: 'https://homologacao.smartpdi.com.br/'
    }
  }
  
  export const prod = {
    apiGateway: {
      URL: 'https://api.smartpdi.com.br/api/v1/'
    },
    clientInfo: {
      APP_URL: 'https://app.smartpdi.com.br/'
    }
  }
  