import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import './App.css';
import { Redirect, Route, BrowserRouter as Router, Switch, RouteComponentProps } from 'react-router-dom';
import { Component } from 'react';
import { Provider } from 'react-redux';
import PublicContent from './components/layout/PublicContent';
import { store } from './components/redux/store';
import { Login } from './components/views/login/Login';
import { isLoggedIn } from './helpers/auth';
import AppContent from './components/layout/AppContent';
import Home from './components/views/home/Home';
import './layout/layout.scss'

interface PrivateRouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  path: string;
  exact?: boolean;
}

interface PublicRouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  path: string;
  exact?: boolean;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (  
  <Route
  {...rest}
    render={(props:any) => {
      if (!isLoggedIn()) {
        return (
          <PublicContent>
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          </PublicContent>
        );
      }
      return (
        <AppContent {...props}>
          <Component {...props} />
        </AppContent>
      );
    }}
  />
);

const PublicRoute = ({ component: Component, ...rest }: PublicRouteProps) => (
  
  <Route
    {...rest}
    render={(props:any) => {
      if (isLoggedIn()) {
        return (          
          <AppContent {...props}>
            <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
          </AppContent>
        );
      }
      return (
        <PublicContent>
          <Component {...props} />
        </PublicContent>
      );
    }}
  />  
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/home" component={Home} />
            {/* <Route path="*" render={(props:any) => <ErrorPage throughRoute {...props} />} /> */}
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
