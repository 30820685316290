import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

interface TextInputProps {
  id?: string;
  label?: string;
  value?: string;
  mask?: string;  
  [key: string]: any; // Para suportar props adicionais
}

class TextInput extends Component<TextInputProps> {
  render() {
    const { label, id, mask, value, ...rest } = this.props;

    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        {mask ? (
          <InputMask {...rest} id={id} value={value || ''} mask={mask} autoClear={false} />
        ) : (
          <InputText {...rest} id={id} value={value || ''} />
        )}
      </>
    );
  }
}

export default TextInput;
