import { applyMiddleware, Store, AnyAction } from 'redux';
import { createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import reducer from './reducer';
import { server } from '../../helpers/server';

export const store: any = createStore(reducer, applyMiddleware(axiosMiddleware(server())));

export const dispatch = (action: AnyAction): void => {
  store.dispatch(action);
}

export const getStore = (): ReturnType<Store['getState']> => {
  return store.getState();
}
