import React, { Component } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import TextInput from '../../inputs/TextInput'
import CommonHelper from '../../../helpers/CommonHelper'
import { login } from '../../../helpers/auth'
import LoginService from '../../../services/publicos/LoginService'

interface LoginState {
    senha: string;
    email: string;
    loading: boolean;
    visibleCompanySelect: boolean;
    visibleRedefinirSenhaModal: boolean;
    visibleAlterarSenhaModal: boolean;
    usuario: object;
    companies: any[];
    selectedCompany: any | null;
    filtroCompanies: string;
    renderCompaniesList: any[];    
  }

export class Login extends Component<any, LoginState> {
    private toast: any;
    private loginService: LoginService;

    constructor(props: any) {
      super(props)
      
      this.state = {
        senha: '',
        email: '',
        loading: false,
        visibleCompanySelect: false,
        visibleRedefinirSenhaModal: false,
        visibleAlterarSenhaModal: false,
        usuario: {},
        companies: [],
        selectedCompany: null,
        filtroCompanies: '',
        renderCompaniesList: [],        
      };
      this.loginService = new LoginService();
    }
  onLogin = async () => {
    try {
      this.setState({ loading: true });  
      let responseLogin = await this.loginService.login({
        password: this.state.senha,
        email: this.state.email,        
      })
      login(responseLogin)
      this.props.history.push('/home')
    } catch (e:any) {
      this.toast.show({
        severity: 'error',
        summary: 'Erro',
        detail: e.message || 'Ops! Ocorreu um erro ao fazer o login!'
      })
    }finally{
      this.setState({ loading: false });
    }
  }

   handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value } as any);
  };

  handleConfirm = () => {
    this.setState({ visibleCompanySelect: false })
    this.onLogin()
  }

  render () {
    const { senha, email, loading } = this.state
   
    return (
      <>
        <div className="login-page">
          <div className="login-body">
            {/* {CommonHelper.isDesktop() && (
              <div className="field col-7 login-logo">
                <div className="logo">
                  <object data="/assets/logo_berkan.svg"></object>
                </div>
                <div className="logo-body">                  
                </div>
              </div>
            )} */}

            <div className={(CommonHelper.isDesktop() ? 'field col-5' : '') + ' login-form'}>
              {/* {!CommonHelper.isDesktop() && (
                <div className="logo">
                  <object data="/assets/logo_berkan.svg"></object>
                </div>
              )} */}
             
              <div className="formgrid grid justify-content-center ">
                <div>
                  <h2 className="login-welcome">Bem-vindo de volta!</h2>
                  <h2 className="login-small">Informe os seus dados abaixo para acessar o sistema:</h2>
                </div>
                <div className="field col-12">
                  <TextInput
                    label="Usuário"
                    name="email"
                    value={email}
                    type="email"
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    
                    autoFocus
                  />
                </div>
                <div className="field col-12" style={{ marginBottom: '0px' }}>
                  <TextInput
                    type="password"
                    label="Senha"
                    name="senha"
                    value={senha}
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    />
                </div>
                <div className="flex justify-content-end" style={{ width: '100%' }}>
                  <small className="login-smaller-link  mr-3 mb-4" onClick={() => this.setState({ visibleRedefinirSenhaModal: true })}>Esqueci minha senha.</small>
                </div>
                <div className="field col-12 no-margin">
                  <Button
                    className="login-button w-full"
                    label="Entrar"
                    icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
                    onClick={this.onLogin}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        
        <Toast ref={(el) => this.toast = el} />
      </>
    )
  }
}
