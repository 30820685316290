import { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../helpers/auth";

export class AppTopbar extends Component {
  

  logout = () => {
    logout()
    window.location.href = '/login'
  }
  
  render () {
    
    return (
      <>
        <div className="layout-topbar clearfix">          
          <Link to="/login" onClick={logout} style={{ color: 'inherit' }}>
          Sair
          </Link>    
        </div>        
      </>
    )
  }
}
