import React, { Component, MouseEvent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { PublicFooter } from './PublicFooter';
import Message from '../utils/Message';

// Definição dos tipos para as props e state
interface PublicContentProps {
  children?: React.ReactNode;
  loading: boolean;
}

interface PublicContentState {
  layoutMode: 'overlay' | 'static';
  layoutColorMode: 'dark' | 'light';
  staticMenuInactive: boolean;
  overlayMenuActive: boolean;
  mobileMenuActive: boolean;
}

class PublicContent extends Component<PublicContentProps, PublicContentState> {
  private menuClick: boolean;

  constructor(props: PublicContentProps) {
    super(props);
    this.state = {
      layoutMode: 'overlay',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };

    this.menuClick = false;

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onWrapperClick(event: MouseEvent<HTMLDivElement>) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event: MouseEvent<HTMLAnchorElement>) {
    this.menuClick = true;
    if (this.isDesktop()) {
      this.setState((prevState) => ({
        staticMenuInactive: !prevState.staticMenuInactive,
      }));
    } else {
      this.setState((prevState) => ({
        mobileMenuActive: !prevState.mobileMenuActive,
      }));
    }
    event.preventDefault();
  }

  onSidebarClick(event: MouseEvent<HTMLDivElement>) {
    this.menuClick = true;
  }

  onMenuItemClick(event: { item: { items?: unknown } }) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  addClass(element: HTMLElement, className: string) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += ' ' + className;
    }
  }

  removeClass(element: HTMLElement, className: string) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive) {
      this.addClass(document.body, 'body-overflow-hidden');
    } else {
      this.removeClass(document.body, 'body-overflow-hidden');
    }
  }

  render() {
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive,
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {this.props.children}

        <PublicFooter />

        <div className="layout-mask"></div>
        {this.props.loading && (
          <div className="loading-container">
            <ProgressSpinner />
          </div>
        )}
        <Message />
      </div>
    );
  }
}

const mapStateToProps = (state: { loading: boolean }) => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(PublicContent);
