export function authToken () {
    return localStorage.getItem('accessToken')
  }
  
    
  export function login (response:any) {
    localStorage.setItem('accessToken', response.data.token)
  }
  
  export function logout () {
    localStorage.removeItem('accessToken')
  }
  
  export function isLoggedIn () {
    const token = localStorage.getItem('accessToken')        
    return !!token && token !== 'null'
  }
  